import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import pic from "../../content/assets/selfie_with_marley_paige_meadows-300x225.jpg"

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`About ABC Interactive LLC`,`website`,`app dev`,`website application`,`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About ABC Interactive LLC website application developnment services"
      />

      <section className="max-w-screen-lg m-auto  mt-20 px-5">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white font-family-opensans">About ABC Interactive LLC</h1>
          <img className="float-right w-1/2 md:w-1/3 rounded-md m-5" alt="hard at work" src={pic} />
        <p className="p-3 mb-4 text-lg md:text-base text-white font-family-opensans">
          ABC Interactive LLC is a small website and mobile app development
          company located in California. Founded by Andrew Chew in 2005 after a
          decade of building engaging online experiences for companies large and
          small. Andrew has also built education games for companies such as
          Score Learning and the Rainforest Action Network, helped author
          chapters on a book for Adobe Press, led teams developing award winning
          training courses while working at Vitesse Learning, and helped build
          next-gen courseware during the “dot com” era of the bay area at The
          Ninth House Network.
        </p>
      </section>
    </Layout>
  )
}

export default AboutPage
